<template>
  <div id="hero-container">
    <img src="../assets/shape_outlines.svg" style="position: absolute; right: -30px; top: 50%; transform: translateY(-50%); z-index: 1;" alt="background image"/>
    <div id="hero-content">
      <div style="display: flex; flex-direction: column;">
        <h1 class="roboto-condensed-regular">Designer Watch Faces<br>for WearOS</h1>
        <p v-if="!submitted">Coming soon. Enter your email to get notified when we launch</p>
        <div v-if="!submitted" class="custom-input-container">
          <input v-model="email" @keyup.enter="submitEmail" class="email-input" type="email" placeholder="Email" />
          <img @click="submitEmail" src="../assets/arrow_right.svg" class="custom-button" alt="Notify Me" />
        </div>
        <p v-if="submitted">Thanks! You will receive an email on the day we launch.</p>
        <span 
          v-if="error != ''"
          style="color: red; font-size: 14px; margin-top: 4px; margin-left: 4px;"
        >{{ error }}</span>
      </div>
      <div>
        <img src="/borgo_watch.png" id="hero-watch"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { database } from '../firebase';
import { ref as dbRef, push } from 'firebase/database';

const email = ref('');
const error = ref('');
const submitted = ref(false);

let loading = false;
const submitEmail = async () => {
  if (loading) return;
  loading = true;
  error.value = '';
  if (email.value) {
    const regex = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,6}$/)
    const isValid = regex.test(email.value);
    if (!isValid) {
      error.value = 'Please enter a valid email address';
    } else {
      const emailRef = dbRef(database, 'emails');
      await push(emailRef, email.value);
      submitted.value = true;
    }
    email.value = '';
    loading = false;
  }
}

</script>

<style scoped>
 #hero-container {
  background-color: #EEEEEE;
  padding: 50px 30px;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
 }

  #hero-content {
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
    z-index: 2;
    align-items: center;
    width: 100%;
  }

 h1 {
  display: none;
  margin-bottom: 40px;
  font-size: 60px;
 }

 p {
  margin-bottom: 20px;
  text-align: center;
 }

 input:focus {
  outline: none;
 }

 .custom-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

 .email-input {
  border: 2px solid var(--color-black-default);
  border-radius: 4px;
  background-color: transparent;
  padding: 17px 15px;
  padding-right: 50px;
  width: 100%;
  letter-spacing: .01em;
 }

 .custom-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  height: 100%;
}

#hero-watch {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  box-shadow: -10px 10px 25px 5px rgba(0, 0, 0, 0.45);
}

@media (min-width: 992px) {
  #hero-container {
    border-radius: 10px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 63.5px 30px;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 50px;
  }

  #hero-content {
    flex-direction: row;
    justify-content: space-around;
    align-items: initial;
    gap: initial;
  }

  h1 {
    display: initial;
  }

  p {
    text-align: left;
  }

  #hero-watch {
    width: 350px;
    height: 350px;
    box-shadow: -17.5px 17.5px 35px 7px rgba(0, 0, 0, 0.45);
  }
}
</style>
