<template>
  <div style="max-width: 1440px; margin: 0 auto;">
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>

</style>
