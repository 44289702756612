// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyAf8HF4npM7TrZrNzcrCcFyZIMGMalUtTQ",
  authDomain: "luxe-watch-faces.firebaseapp.com",
  projectId: "luxe-watch-faces",
  storageBucket: "luxe-watch-faces.appspot.com",
  messagingSenderId: "415360916389",
  appId: "1:415360916389:web:e1c45998bd4f863958664c",
  measurementId: "G-BXCHNF71MF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

export { database };