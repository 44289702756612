<template>
  <div id="header-container">
    <div id="header-logo-container">
      <img src="../assets/luxe_logo.svg" alt="Luxe Watch Faces logo"/>
      <h1 class="roboto-condensed-medium">luxewatchfaces</h1>
    </div>
    <div id="nav-bar" style="gap: 80px;">
      <div class="roboto-condensed-light" id="nav-bar-links">
        <a href="#"  @click.prevent="emitEvent('Faces')">Faces</a>
        <a href="#"  @click.prevent="emitEvent('About')">About</a>
      </div>
      <div class="roboto-condensed-bold" id="play-store-link">
        <a href="#">Play Store</a>
        <img src="../assets/arrow_up_right.svg"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits } from 'vue';

const emit = defineEmits(['linkClicked']);

const emitEvent = (link) => {
  emit('linkClicked', link);
};
</script>

<style scoped>
  #header-container {
    display: flex;
    justify-content: space-between;
    margin: 10px 30px;
  }

  #header-logo-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  #header-logo-container img {
    width: 32px;
    height: 32px;
  }

  h1 {
    font-size: 20px;
  }

  #nav-bar {
    display: flex;
    align-items: center;
  }

  #nav-bar-links {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  #play-store-link {
    display: none;
  }

  a {
    color: var(--color-black-default);
    font-size: 16px;
    text-decoration: none;
  }

  @media (min-width: 992px) {
    #header-container {
      margin: 40px 30px;
    }

    #header-logo-container img {
    width: 48px;
    height: 48px;
  }

    #play-store-link {
      display: flex;
    }

    #nav-bar-links {
      gap: 50px;
    }
  }
</style>
