export const data = [
  {
    id: '0',
    name: 'Borsha',
    color: 'Black',
    image: 'borsha_watch.png',
    startBG: '#818F9E',
    endBG: '#9CB0C5',
  },
  {
    id: '1',
    name: 'Bindu',
    color: 'White',
    image: 'bindu_watch.png',
    startBG: '#ECEFF2',
    endBG: '#DEDEDE',
  },
  {
    id: '2',
    name: 'Morici',
    color: 'White',
    image: 'morici_watch.png',
    startBG: '#B3B3B3',
    endBG: '#FFFCE2',
  },
  {
    id: '3',
    name: 'Shikor',
    color: 'Amber',
    image: 'shikor_watch.png',
    startBG: '#B6B7BC',
    endBG: '#E2E9FF',
  },
  {
    id: '4',
    name: 'Morici',
    color: 'Green',
    image: 'morici_green_watch.png',
    startBG: '#96B29E',
    endBG: '#DDF9E3',
  },
  {
    id: '5',
    name: 'Borgo',
    color: 'Primary Colors',
    image: 'borgo_watch.png',
    startBG: '#DEDEDE',
    endBG: '#ECEFF2',
  },
  {
    id: '6',
    name: 'Porda',
    color: 'Green',
    image: 'porda_green_watch.png',
    startBG: '#485037',
    endBG: '#97AB70',
  },
  {
    id: '7',
    name: 'Porda',
    color: 'Metal',
    image: 'porda_metal_watch.png',
    startBG: '#B7B7B7',
    endBG: '#F2F2F2',
  },
  {
    id: '8',
    name: 'Porda',
    color: 'Black',
    image: 'porda_black_watch.png',
    startBG: '#4E555D',
    endBG: '#8A8FA9',
  },
  {
    id: '9',
    name: 'Borsha',
    color: 'White',
    image: 'borsha_white_watch.png',
    startBG: '#B7B7B7',
    endBG: '#F2F2F2',
  },
  {
    id: '10',
    name: 'Jhapsha',
    color: 'Black',
    image: 'jhapsha_watch.png',
    startBG: '#535060',
    endBG: '#948DB3',
  },
  {
    id: '11',
    name: 'Ostitto',
    color: 'White',
    image: 'ostitto_watch.png',
    startBG: '#B3B3B3',
    endBG: '#FFFCE0',
  },
  {
    id: '12',
    name: 'Borgo',
    color: 'Black',
    image: 'borgo_black_watch.png',
    startBG: '#DEDEDE',
    endBG: '#ECEFF2',
  },
  {
    id: '13',
    name: 'Shikor',
    color: 'White',
    image: 'shikor_white_watch.png',
    startBG: '#B6B7BC',
    endBG: '#E2E9FF',
  },
  {
    id: '14',
    name: 'Ostitto',
    color: 'Amber',
    image: 'ostitto_amber_watch.png',
    startBG: '#B3B3B3',
    endBG: '#FFFCE0',
  },
  {
    id: '15',
    name: 'Borsha',
    color: 'Blue',
    image: 'borsha_blue_watch.png',
    startBG: '#B6B9BC',
    endBG: '#E2F3FF',
  },
  {
    id: '16',
    name: 'Borsha',
    color: 'Amber',
    image: 'borsha_amber_watch.png',
    startBG: '#D3C7B1',
    endBG: '#ECE0BF',
  },
  {
    id: '17',
    name: 'Jhapsha',
    color: 'White',
    image: 'jhapsha_white_watch.png',
    startBG: '#8B8B97',
    endBG: '#DBDBE7',
  },
];